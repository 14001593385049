<template>
  <div class="main__content content">
    <div class="pacs-settings-table">
      <div class="pacs-settings-table__group-setting">
        <SmartInputText
          v-model="searchText"
          :caption="$t('search')"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_big"
          size="l"
          @keyup.native.enter="applyTableSettings()"
        >
          <CamsButton
            position="r"
            size="l"
            type="button"
            icon-type="only"
            @click="applyTableSettings()"
          >
            <svg class="icon-search">
              <use xlink:href="#icon-search" />
            </svg>
          </CamsButton>
        </SmartInputText>

        <SmartInputDate
          v-model="archiveFrom"
          :caption="$t('startDate')"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: new Date()}"
          @input="applyTableSettings()"
        />
        <SmartInputDate
          v-model="archiveTo"
          :caption="$t('endDate')"
          :config-flat-pickr="{minDate: archiveFrom, maxDate: new Date()}"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_small"
          size="l"
          @input="applyTableSettings()"
        />
        <SmartInputText
          v-model="number"
          :caption="$t('number')"
          class="pacs-settings-table__settings-control"
          size="l"
          step="0.1"
          @input="applyTableSettings()"
        />
        <SmartSwitch
          v-model="access"
          :one-row="false"
          :caption="$t('access')"
          class="pacs-settings-table__settings-control pacs-settings-table__settings-control_up"
          @input="applyTableSettings()"
        />
        <CamsButton
          class="pacs-settings-table__settings-control"
          priority="primary"
          size="l"
          type="button"
          @click="downloadTableAsCSVReport()"
        >
          {{ $t('downloadReport') }}
        </CamsButton>
        <SmartSelect
          v-model="pageSize"
          :options="optionsPageSize"
          :caption="$t('itemsPerPage')"
          class="pacs-settings-table__settings-control"
          size="s"
          @change="applyTableSettings"
        />
      </div>
    </div>

    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else>
      <p v-show="dataRows.length === 1000" class="pacs-settings-table__warn">
        {{ $t('maxRowsWarning') }}
      </p>
      <PacsTable
        :column-captions=" {
          text: 'text',
          cameraNumber: 'cameraNumber',
          date: 'date',
          number: 'carNumber',
          access: 'accessGranted',
          deviceId: 'deviceId',
          employeeId: 'employeeId',
        }"
        :column-names="columnNames"
        :data-rows="dataRows"
        :page-count="pageCount"
        :total-count="dataRows.length"
        :view-only-mode="true"
        :current-page="currentPage"
        @new-page="selectPage"
      >
        <template #customThCells />
        <template #customTdCells="{dataRow}">
          <CamsButton
            icon-type="only"
            :title="$t('screenshotEvent')"
            type="button"
            @click="showFullScreenshotForEventInTable(dataRow['_analyticMessage_'])"
          >
            <svg><title>Скриншот</title>
              <use xlink:href="#icon-screen-preview" />
            </svg>
          </CamsButton>
          <CamsButton
            icon-type="only"
            :title="$t('eventVideo')"
            type="button"
            @click="playEventStartForEventInTable(dataRow['_analyticMessage_'])"
          >
            <svg>
              <use xlink:href="#icon-video-preview" />
            </svg>
          </CamsButton>
          <CamsButton
            icon-type="only"
            type="button"
            :title="$t('downloadEventVideo')"
            @click="downloadEventVideoInTable(dataRow['_analyticMessage_'])"
          >
            <svg>
              <use xlink:href="../../../../assets/img/icons.svg#cloud-archive" />
            </svg>
          </CamsButton>
        </template>
      </PacsTable>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {ACTION_LOAD_PACS_CAR_NUMBER_REPORT} from "@/store/pacs/analytics/index.js";
import PacsTable from "@/components/pacs/PacsTable.vue";
import {SPECIAL_TYPES_DATA_IN_CELLS} from "@/store/pacs/helpers.js";
import {eventTableMixin} from "@/components/pacs/analytics/mixins.js";
import {ROUTE_PACS_EDIT_DEVICE, ROUTE_PACS_EDIT_EMPLOYEE} from "@/router/names.js";

/**
 * Компонент отображения таблицы и фильтров с информацией по событиям распознавания а/м.
 */
export default {
  name: "TableCarNumber",
  mixins: [
    eventTableMixin,
  ],
  components: {
    PacsTable
  },
  data() {
    return {
      columnNames: ["text", "cameraNumber", "date", "number", "access", "deviceId", "employeeId"],
      columnCaptions: {
        text: this.$t('eventText'),
        cameraNumber: this.$t('cameraNumber'),
        date: this.$t('eventDate'),
        number: this.$t('carNumber'),
        access: this.$t('accessGranted')
      },
      archiveFrom: null,
      archiveTo: null,
      number: null,
      access: null,
      searchText: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  methods: {
    selectPage(page) {
      this.currentPage = page; // Устанавливаем текущую страницу
      this.applyTableSettings(); // Применяем фильтры и загружаем данные для новой страницы
    },
    /**
     * @see eventTableMixin.methods.applyTableSettings
     */
    applyTableSettings() {
      const queryParams = {
        f: +this.clearArchiveFrom,
        t: +this.clearArchiveTo,
        n: this.number,
        a: this.access,
        s: this.searchText,
        c: this.currentPage,
        p: this.pageSize,
      };
      this.stringifyQueryParams(queryParams);
    },
    /**
     * @see eventTableMixin.methods.assignQueryParams
     */
    assignQueryParams(parsedQueryParams) {
      if (_.isEmpty(parsedQueryParams)) {
        this.resetFilters();
      } else {
        this.archiveFrom = new Date(parsedQueryParams.f);
        this.archiveTo = new Date(parsedQueryParams.t);
        this.number = parsedQueryParams.n;
        this.access = parsedQueryParams.a;
        this.searchText = parsedQueryParams.s;
        this.currentPage = parsedQueryParams.c;
        this.pageSize = parsedQueryParams.p;
      }
    },
    /**
     * @see eventTableMixin.methods.loadPage.
     */
    async loadPage() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }

      this.isLoading = true;
      let responseData;
      try {
        responseData = await this.$store.dispatch(`pacs/analytics/${ACTION_LOAD_PACS_CAR_NUMBER_REPORT}`, {
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
          number: this.number,
          access: this.access,
          query: this.searchText,
          page: this.currentPage,
          pageSize: this.pageSize,
        });
        this.currentPage = responseData.page.current || null;
        this.pageCount = responseData.page.all || null;
        this.pageSize = responseData.page.page_size || null;
      } catch (error) {
        if (!error) {
          return; // Для прерванных ранее отправленных запросов.
        }
        this.$camsdals.alert(this.$t("loadingError"));
      }
      responseData = responseData.results ?? [];
      this.dataRows = responseData.map((message) => {
        const deviceIdData = {
                type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
                value: this.$t("view"),
                params: {name: ROUTE_PACS_EDIT_DEVICE, params: {deviceId: message.deviceId}},
              },
              employeeIdData = {
                type: SPECIAL_TYPES_DATA_IN_CELLS.ROUTE,
                value: this.$t("view"),
                params: {name: ROUTE_PACS_EDIT_EMPLOYEE, params: {employeeId: message.employeeId}},
              };
        return {
          "text": {
            value: message.text || this.$t("unknown"),
          },
          "cameraNumber": {
            value: message.cameraNumber,
          },
          "date": {
            type: SPECIAL_TYPES_DATA_IN_CELLS.DATE_TIME,
            value: message.date,
          },
          "number": {
            value: message.number,
          },
          access: {
            type: SPECIAL_TYPES_DATA_IN_CELLS.BOOLEAN,
            value: message.access,
          },
          deviceId: message.deviceId ? deviceIdData : {},
          employeeId: message.employeeId ? employeeIdData : {},
          _analyticMessage_: message, // Объект сообщения для передачи в события клика в кнопках на строчках в таблице. См. в этом шаблоне.
        };
      });
      this.isLoading = false;
    },
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = this.maxArchiveTo || new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
      this.number = "";
      this.access = null;
      this.searchText = "";
      this.currentPage = 1;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    /**
     * Выгрузка всех данных в CSV.
     */
    async  downloadTableAsCSVReport () {
      this.isLoading = true;
      const csvHeaders = [
        this.$t('name'),
        this.$t('cameraNumber'),
        this.$t('date'),
        this.$t('carNumber'),
        this.$t('access')
      ];

      const formatDataForCSV = (message) => {
        return [
          message.text || this.$t('unknown'),
          message.cameraNumber,
          this.$options.filters.localDateTime(message.date),
          message.number,
          message.access ? this.$t('yes') : this.$t('no')
        ];
      };
      const filters = {};
      if (this.number != null) filters.number = this.number;
      if (this.access != null) filters.access = this.access;
      if (this.searchText) filters.query = this.searchText;
      await this.downloadTableAsCSV(`pacs/analytics/${ACTION_LOAD_PACS_CAR_NUMBER_REPORT}`, this.$t('carNumberReport'), csvHeaders, formatDataForCSV,filters);

      this.isLoading = false;
    }
  }
};
</script>
