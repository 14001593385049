/**
 * Отдельный vuex модуль для взаимодействия по analytics/motion_alarm-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для motionAlarms. Вызов действия начинать с "analytics/motionAlarms/"
export const ACTION_LOAD_MOTION_ALARMS_SETTINGS = "LOAD_MOTION_ALARMS_SETTINGS";
export const ACTION_LOAD_MOTION_ALARM_SETTINGS = "LOAD_MOTION_ALARM_SETTINGS";
export const ACTION_LOAD_MOTION_ALARM_REPORT = "LOAD_MOTION_ALARM_REPORT";

/**
 * Класс для представления настроек аналитики по распознаванию движения.
 */
export class MotionAlarmSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.motion_alarm;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик по распознаванию движения.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<MotionAlarmSettings>>}
     */
    async [ACTION_LOAD_MOTION_ALARMS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/motion_alarm/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawMotionAlarmSettings => new MotionAlarmSettings(rawMotionAlarmSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики по распознаванию движения с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<MotionAlarmSettings>}
     */
    async [ACTION_LOAD_MOTION_ALARM_SETTINGS]({dispatch}, {cameraNumber}) {
      const motionAlarmsSettings = await dispatch(ACTION_LOAD_MOTION_ALARMS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(motionAlarmsSettings)) {
        return null;
      }
      return motionAlarmsSettings[0];
    },
    /**
     * Загрузка перечня событий по распознаванию движения с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @return {Promise.<Array.<MotionAlarmMessage>>}
     */
    async [ACTION_LOAD_MOTION_ALARM_REPORT](context, {cameraNumber, archiveFrom, archiveTo, page, page_size}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/motion_alarm/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          page: page,
          page_size: page_size
        });
        const messages = response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.motion_alarm));

        return {
          messages,
          response: response.data
        };
      } catch {
        return {
          messages: [],
          response: null
        };
      }
    },
  },
};
