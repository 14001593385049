/**
 * Отдельный vuex модуль для взаимодействия по analytics/helmet-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для helmets. Вызов действия начинать с "analytics/helmets/"
export const ACTION_LOAD_HELMETS_SETTINGS = "LOAD_HELMETS_SETTINGS";
export const ACTION_LOAD_HELMET_SETTINGS = "LOAD_HELMET_SETTINGS";
export const ACTION_LOAD_HELMET_EVENTS = "LOAD_HELMET_EVENTS";
export const ACTION_LOAD_HELMET_EVENT = "LOAD_HELMET_EVENT";
export const ACTION_LOAD_HELMET_REPORT = "LOAD_HELMET_REPORT";

/**
 * Класс для представления настроек аналитики определения касок.
 */
export class HelmetSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.helmet;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик определения касок.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<HelmetSettings>>}
     */
    async [ACTION_LOAD_HELMETS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/helmet/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawHelmetSettings => new HelmetSettings(rawHelmetSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики определения касок с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<HelmetSettings>}
     */
    async [ACTION_LOAD_HELMET_SETTINGS]({dispatch}, {cameraNumber}) {
      const helmetsSettings = await dispatch(ACTION_LOAD_HELMETS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(helmetsSettings)) {
        return null;
      }
      return helmetsSettings[0];
    },
    /**
     * Загрузка перечня событий по аналитике касок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<HelmetMessage>>}
     */
    async [ACTION_LOAD_HELMET_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/helmet/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.helmet));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по аналитики автомобильных номеров с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<HelmetMessage>}
     */
    async [ACTION_LOAD_HELMET_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const helmetsSettings = await dispatch(ACTION_LOAD_HELMET_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(helmetsSettings)) {
        return null;
      }
      return helmetsSettings[0];
    },
    /**
     * Загрузка перечня событий по определению касок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} query
     * @return {Promise.<Array.<HelmetMessage>>}
     */
    async [ACTION_LOAD_HELMET_REPORT](context, {cameraNumber, archiveFrom, archiveTo, query = null, page, page_size}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/helmet/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          query: query,
          page: page,
          page_size: page_size
        });
        const messages = response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.helmet));

        return {
          messages,
          response: response.data
        };
      } catch {
        return {
          messages: [],
          response: null
        };
      }
    },
  },
};
