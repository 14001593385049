<template>
  <div class="analytic-report">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && cameraInfo.isAvailableArchive()">
      <div class="analytic-report__settings">
        <SmartSelect
          v-model="timeBucket"
          :options="settingsTimeBucket"
          :caption="$t('reportType')"
          width="fill"
          class="analytic-report__settings-control analytic-report__settings-control_small"
          @input="loadReportTimeBucket"
        />
        <SmartInputDate
          ref="archiveFromInput"
          v-model="archiveFrom"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: new Date()}"
          :caption="$t('startDate')"
          :locale="this.locale"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartInputDate
          ref="archiveToInput"
          v-model="archiveTo"
          :config-flat-pickr="{minDate: archiveFrom, maxDate: new Date()}"
          :caption="$t('endDate')"
          :locale="this.locale"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <CamsButton
          class="analytic-report__settings-control"
          priority="primary"
          type="button"
          @click="downloadTableAsCSVReport()"
        >
          {{ $t('downloadReport') }}
        </CamsButton>
      </div>
      <div class="analytic-report__pagination">
        <paginate
          v-model="currentPage"
          class="analytic-report__pagination_smart-pagination"
          :click-handler="loadReportTimeBucket"
          :page-count="allPages"
          :page-range="5"
          active-class="smart-pagination__page_active"
          break-view-class="smart-pagination__page_collapse"
          container-class="smart-pagination"
          next-class="smart-pagination__page smart-pagination__next-prev"
          next-link-class="smart-pagination__page-link small"
          next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
          page-class="smart-pagination__page"
          page-link-class="smart-pagination__page-link small"
          prev-class="smart-pagination__page smart-pagination__next-prev"
          prev-link-class="smart-pagination__page-link small"
          prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
        />
        <SmartSelect
          v-model="pageSize"
          :options="optionsPageSize"
          :caption="$t('itemsPerPage')"
          width="fill"
          class="analytic-report__pagination_smart-select-pagination"
          size="s"
          @input="setPageSize(pageSize)"
        />
      </div>
      <div class="analytic-report__table cams-table-wrapper">
        <SpinnerLoading v-if="isLoadingReport" class="loader_center" color="blue" />
        <table v-if="timeBucket!=='defaultReport'" class="cams-table">
          <tr>
            <th class="cams-table__cell cams-table__cell_fixed-width" />
            <th class="cams-table__cell" style="width: 400px">
              {{ $t('date') }}
            </th>
            <th class="cams-table__cell">
              {{ $t('inCount') }}
            </th>
            <th class="cams-table__cell">
              {{ $t('outCount') }}
            </th>
          </tr>
          <template v-for="message in messages.results">
            <tr :key="message.id" :class="{'warning warning-bg': message.alarm}">
              <td class="cams-table__cell cams-table__cell_fixed-width" />
              <td v-if="timeBucket!=='1'" class="cams-table__cell">
                {{ message.start | localDateTime }}  - {{ message.end | localDateTime }}
              </td>
              <td v-if="timeBucket==='1'" class="cams-table__cell">
                {{ message.start | localDateTime }}  - {{ message.end | localDateTime }}
              </td>
              <td class="cams-table__cell">
                {{ message.in_count }}
              </td>
              <td class="cams-table__cell">
                {{ message.out_count }}
              </td>
            </tr>
          </template>
        </table>
        <table v-else-if="messages" class="cams-table">
          <tr>
            <th class="cams-table__cell cams-table__cell_fixed-width" />
            <th class="cams-table__cell">
              {{ $t('text') }}
            </th>
            <th class="cams-table__cell">
              {{ $t('date') }}
            </th>
          </tr>
          <template v-for="message in messages">
            <tr :key="message.id" :class="{'warning warning-bg': message.alarm}">
              <td class="cams-table__cell cams-table__cell_fixed-width">
                <div class="analytic-report__event-actions">
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('eventVideo')"
                    type="button"
                    @click="playEventStart(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-video-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('downloadEventVideo')"
                    type="button"
                    @click="downloadEventVideo(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="../../../assets/img/icons.svg#cloud-archive" />
                    </svg>
                  </CamsButton>
                </div>
              </td>
              <td class="cams-table__cell">
                {{ message.text === 'in' ? $t('entrance') : $t('exit') }}
              </td>
              <td class="cams-table__cell">
                {{ message.date | localDateTime }}
              </td>
            </tr>
          </template>
        </table>

        <template v-else>
          <p>{{ $t('noEvents') }}</p>
        </template>
      </div>
    </template>

    <template v-else>
      <p>{{ $t('archiveError') }}</p>
    </template>

    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {analyticReportMixin} from "@/components/oneScreen/reports/mixins.js";
import {downloadCSV} from "@/utils/helpers.js";
import {
  ACTION_LOAD_PEOPLE_COUNT_REPORT,
  ACTION_LOAD_PEOPLE_COUNT_REPORT_COUNT_BUCKET,
} from "@/store/analytics/peopleCount/index.js";

/**
 * Отчет по подсчету посетителей.
 */
export default {
  name: "PeopleReportScreen",
  mixins: [
    analyticReportMixin,
  ],
  data() {
    return {
      archiveFrom: null,
      archiveTo: null,
      searchText: null,
      timeBucket: "defaultReport",
      settingsTimeBucket: null,
      calendarOpen: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo
        ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime()))
        : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo
        ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime()))
        : null;
    },
  },
  watch: {
    // Отслеживание значений фильтров для автоматического обновления отчета.
    archiveFrom(newStart) {
      if (newStart && this.archiveTo && this.archiveTo < newStart) {
        this.archiveTo = newStart;
      }
      this.debouncedLoadReportBucket();
    },
    archiveTo(newEnd) {
      if (newEnd && this.archiveFrom && newEnd < this.archiveFrom) {
        this.archiveTo = this.archiveFrom;
      }
      this.debouncedLoadReportBucket();
    },
    searchText() {
      this.debouncedLoadReportBucket();
    },
    currentPage() {
      this.debouncedLoadReportBucket();
    },
    pageSize() {
      this.debouncedLoadReportBucket();
    },
  },
  /**
   * Регистрируется отложенная загрузка отчета для случаев быстрого изменения фильтров.
   */
  created() {
    this.debouncedLoadReportBucket = _.debounce(this.loadReportTimeBucket, 350);
    this.getPermissionsForSelect();
  },
  methods: {
    getPermissionsForSelect() {
      return this.settingsTimeBucket ={
        defaultReport: this.$t('defaultReport'),
        "1": this.$t('hourly'),
        "24": this.$t('daily'),
        "168": this.$t('weekly'),
        "720": this.$t('monthly')
      };
    },

    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
      this.searchText = "";
      this.currentPage = 1;
    },
    /**
     * Загрузка отчета по заданным параметрам.Загрузится либо общий отчет либо агрегированный отчет в зависимости от timeBucket
     */
    async loadReportTimeBucket() {
      this.isLoading = true;
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }
      if (this.timeBucket==='defaultReport'){
        const {messages, response} = await this.$store.dispatch(`analytics/peopleCount/${ACTION_LOAD_PEOPLE_COUNT_REPORT}`, {
          cameraNumber: this.cameraInfo.number,
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
          query: this.searchText,
          page: this.currentPage,
          page_size: this.pageSize,
        });
        this.messages = messages || []; // Если данных нет, устанавливаем пустой массив
        this.allPages = response.page.all || 1; // Общее количество страниц
        this.currentPage = response.page.current || 1; // Текущая страница
        this.currentPage > this.allPages ? this.currentPage = 1 : this.currentPage
      }  else
      {
        this.messages = [];
        this.messages = await this.$store.dispatch(`analytics/peopleCount/${ACTION_LOAD_PEOPLE_COUNT_REPORT_COUNT_BUCKET}`, {
          cameraNumber: this.cameraInfo.number,
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
          timeBucket: this.timeBucket,
        });
      }
      this.isLoading = false;
    },
    /**
     * Форматирование времени без секунд.
     */
    localDateHours(date) {
      if (!date) {
        return "-";
      }
      const d = new Date(date);
      return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1)
        .toString()
        .padStart(2, "0")}.${d.getFullYear()} ${d.getHours().toString().padStart(2, "0")}:${d
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
  async downloadTableAsCSVReport() {
      this.isLoading = true;
      let dateFrom = moment.tz(this.clearArchiveFrom, moment.tz.guess()).format("DD.MM.YYYY_HH.mm");
      let dateTo = moment.tz(this.clearArchiveTo, moment.tz.guess()).format("DD.MM.YYYY_HH.mm");
      const filenameBuilder = `${this.$t('peopleCountReport')}_${dateFrom}-${dateTo}_${this.cameraInfo.title}.csv`;
      const headers =  [this.$t('text'), this.$t('date')];
      if (this.timeBucket === "defaultReport") {
        const apiAction = `analytics/peopleCount/${ACTION_LOAD_PEOPLE_COUNT_REPORT}`
        const params = {
          cameraNumber: this.cameraInfo.number,
          archiveFrom: this.clearArchiveFrom,
          archiveTo: this.clearArchiveTo,
        };

        const rowBuilder = (message) => {
          console.log(message)
          return `${message.text === 'in' ? this.$t('entrance') : this.$t('exit')},${this.$options.filters.localDateTime(message.date)}\r\n`;
        };
        await this.downloadTableAsCSV(apiAction, params, headers, filenameBuilder, rowBuilder);
      }

      else {
        let csvText;
        let headers = [this.$t('date'), this.$t('inCount'), this.$t('outCount'),];
        csvText = `${headers.join(",")}\r\n`;
        this.messages.results.forEach((message) => {
          const formattedDate = `${this.localDateHours(message.start)} - ${this.localDateHours(
            message.end
          )}`;
          csvText += `${formattedDate},${message.in_count},${message.out_count}\r\n`;
        });
        downloadCSV(filenameBuilder, csvText);
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "./analytic-report.scss";
</style>
