import {loadCameraInfoMixin} from "@/components/oneScreen/mixins.js";
import {eventHandlersMixin} from "@/components/events/mixins.js";
import {ACTION_LOAD_WHOLE_RECORDING_STATUS} from "@/store/cameras/index.js";
import {downloadCSV} from "@/utils/helpers.js";


// max row limit for csv output file
const MAX_ROWS_LIMIT = 100000;
/**
 * Примесь для компонентов отчетов с общими функциями.
 * Отчеты нужны для показа архивных событий с камеры в виде таблицы, с возможностью перехода к просмотру видео этих событий,
 * скриншотов и скачивания самих отчетов.
 */
export const analyticReportMixin = {
  mixins: [
    loadCameraInfoMixin,
    eventHandlersMixin,
  ],
  data() {
    const minEventArchiveFrom = new Date();
    const maxEventArchiveTo = new Date();
    minEventArchiveFrom.setDate(minEventArchiveFrom.getDate() - 31);
    maxEventArchiveTo.setDate(maxEventArchiveTo.getDate() + 1);

    return {
      currentPage: 1, // текущая страница
      pageSize: 60, // размер страницы (количество элементов на странице)
      allPages: 1, // общее количество страниц
      optionsPageSize: [20, 40, 60, 100, 200,500,1000], // варианты размера страниц
      isLoadingReport: false,
      messages: [],
      // Начало и конец отрезка времени запрошенного архивного фрагмента - для проброса между компонентами.
      minEventArchiveFrom: minEventArchiveFrom,
      maxEventArchiveTo: maxEventArchiveTo,
      // Отдельно надо знать доступность видео, чтобы иметь возможность показать его только там где оно есть.
      minVideoArchiveFrom: new Date(),
    };
  },
  watch: {
    // Отслеживаем изменения в minEventArchiveFrom и maxEventArchiveTo
    minEventArchiveFrom(newDate) {
      this.validateDates(newDate, this.maxEventArchiveTo);
    },
    maxEventArchiveTo(newDate) {
      this.validateDates(this.minEventArchiveFrom, newDate);
    },
  },
  methods: {
    /**
     * Валидация диапазона дат, если дата конца меньше даты начала.
     * @param {Date} from - Дата начала
     * @param {Date} to - Дата конца
     */
    validateDates(from, to) {
      if (to < from) {
        // Выводим ошибку пользователю
        this.$camsdals.alert(this.$t('errorInvalidDateRange'));

        // Сбрасываем некорректные значения
        this.maxEventArchiveTo = new Date(from.getTime() + 24 * 60 * 60 * 1000); // Устанавливаем конец как минимум через день после начала
      }
    },
    async fetchAllPages(apiAction, params) {
      let allMessages = [];
      let currentPage = 1;
      let allPages = 1;
      let totalRows = 0;
      const pageSize = 1000;  // Размер страницы

      do {
        const {messages, response} = await this.$store.dispatch(apiAction, {
          ...params,
          page: currentPage,
          page_size: pageSize,
        });
        totalRows += messages.length;
        if (totalRows > MAX_ROWS_LIMIT) {
          throw this.$camsdals.alert(this.$t('maxRowLimit') + MAX_ROWS_LIMIT);
        }
        allMessages = [...allMessages, ...messages];
        allPages = response.page.all || 1;
        currentPage++;
      } while (currentPage <= allPages && totalRows < MAX_ROWS_LIMIT);

      return allMessages;
    },

    async downloadTableAsCSV(apiAction, params, headers, filename, rowBuilder) {
      try {
        const allMessages = await this.fetchAllPages(apiAction, params);
        const filenameOutput = filename;
        let csvText = `${headers.join(",")}\r\n`;

        allMessages.forEach(message => {
          csvText += rowBuilder(message);
        });

        downloadCSV(filenameOutput, csvText);
      } catch (error) {
        console.error(error.message);
        alert(error.message);  // Показываем ошибку пользователю
      }
    },

    setPageSize(size) {
      this.pageSize = size;
    },
    /**
     * Функция для перекрытия и запуска операций, которые необходимо выполнить после загрузки
     * информации по камере.
     *
     * @see loadCameraInfoMixin.methods.afterLoadCameraInfo
     * @return {Promise}
     */
    async afterLoadCameraInfo() {
      await this.receiveWholeRecordingStatus();
      this.resetFilters();
    },
    /**
     * Получение меток доступного фрагмента архива для корректного отображения календаря.
     */
    async receiveWholeRecordingStatus() {
      try {
        const wholeRecordingStatus = await this.$store.dispatch(`cameras/${ACTION_LOAD_WHOLE_RECORDING_STATUS}`, {
          cameraNumber: this.cameraInfo.number,
          domain: this.cameraInfo.server.domain,
          token: this.cameraInfo.tokenDVR,
        });
        this.minVideoArchiveFrom = new Date(wholeRecordingStatus[0] * 1000);
      } catch {
        // Игнорируем ошибки.
      }
    },
    /**
     * Сброс фильтров в начальное состояние.
     */
    resetFilters() {
      const archiveTo = new Date(),
        archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.minEventArchiveFrom = archiveFrom;
      this.maxEventArchiveTo = archiveTo;
    },
  }
};
