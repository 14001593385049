/**
 * Отдельный vuex модуль для взаимодействия по analytics/people_counter-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для people_counts. Вызов действия начинать с "analytics/PeopleCounts/"
export const ACTION_LOAD_PEOPLE_COUNTS_SETTINGS = "LOAD_PEOPLE_COUNTS_SETTINGS";
export const ACTION_LOAD_PEOPLE_COUNT_SETTINGS = "LOAD_PEOPLE_COUNT_SETTINGS";
export const ACTION_LOAD_PEOPLE_COUNT_EVENTS = "LOAD_PEOPLE_COUNT_EVENTS";
export const ACTION_LOAD_PEOPLE_COUNT_EVENT = "LOAD_PEOPLE_COUNT_EVENT";
export const ACTION_LOAD_PEOPLE_COUNT_REPORT = "LOAD_PEOPLE_COUNT_REPORT";
export const ACTION_LOAD_PEOPLE_COUNT_REPORT_COUNT_BUCKET = "ACTION_LOAD_PEOPLE_COUNT_REPORT_COUNT_BUCKET";

/**
 * Класс для представления настроек аналитики пересечения зоны.
 */
export class PeopleCountSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.people_counter;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек по аналитике подсчета посетителей.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<PeopleCountSettings>>}
     */
    async [ACTION_LOAD_PEOPLE_COUNTS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawPeopleCountSettings => new PeopleCountSettings(rawPeopleCountSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики подсчету посетителей с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<PeopleCountSettings>}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_SETTINGS]({dispatch}, {cameraNumber}) {
      const PeopleCountsSettings = await dispatch(ACTION_LOAD_PEOPLE_COUNTS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(PeopleCountsSettings)) {
        return null;
      }
      return PeopleCountsSettings[0];
    },
    /**
     * Загрузка перечня событий по подсчету посетителей для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<PeopleCountMessage>>}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.people_counter));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по подсчету посетителей для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<PeopleCountMessage>}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const PeopleCountsSettings = await dispatch(ACTION_LOAD_PEOPLE_COUNT_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(PeopleCountsSettings)) {
        return null;
      }
      return PeopleCountsSettings[0];
    },
    /**
     * Загрузка перечня событий по подсчету посетителей с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} query
     * @return {Promise.<Array.<PeopleCountMessage>>}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_REPORT](context, {cameraNumber, archiveFrom, archiveTo, query = null,page, page_size}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          query: query,
          page: page,
          page_size: page_size
        });
        const messages = response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.people_counter));

        return {
          messages,
          response: response.data
        };
      } catch {
        return {
          messages: [],
          response: null
        };
      }
    },
    /**
     * Загрузка перечня событий по подсчету посетителей с камеры для формирования агрегированного отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} timeBucket
     * @return {Promise.<Array.<PeopleCountMessage>>}
     */
    async [ACTION_LOAD_PEOPLE_COUNT_REPORT_COUNT_BUCKET](context, {cameraNumber, timeBucket, archiveFrom, archiveTo}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/people_counter/report/time_bucket/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          time_bucket: timeBucket,
        });
        return response.data;
      } catch {
        return [];
      }
    },
  },
};
