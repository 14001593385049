/**
 * Отдельный vuex модуль для взаимодействия по analytics/crowd-API
 */

import {ANALYTICS, AnalyticSettings} from "@/store/analytics/helpers.js";
import makeMessage from "@/store/analytics/analyticMessage.js";

// actions для crowds. Вызов действия начинать с "analytics/crowds/"
export const ACTION_LOAD_CROWDS_SETTINGS = "LOAD_CROWDS_SETTINGS";
export const ACTION_LOAD_CROWD_SETTINGS = "LOAD_CROWD_SETTINGS";
export const ACTION_LOAD_CROWD_EVENTS = "LOAD_CROWD_EVENTS";
export const ACTION_LOAD_CROWD_EVENT = "LOAD_CROWD_EVENT";
export const ACTION_LOAD_CROWD_REPORT = "LOAD_CROWD_REPORT";

/**
 * Класс для представления настроек аналитики определения масок.
 */
export class CrowdSettings extends AnalyticSettings {
  get analyticType() {
    return ANALYTICS.crowd;
  }
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка настроек аналитик определения масок.
     *
     * @param {Object} context
     * @param {Array.<String>} camerasNumbers
     * @return {Promise.<Array.<CrowdSettings>>}
     */
    async [ACTION_LOAD_CROWDS_SETTINGS](context, {camerasNumbers}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/crowd/settings/", {camera_numbers: camerasNumbers});
        return response.data.results.map(rawCrowdSettings => new CrowdSettings(rawCrowdSettings));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка настроек аналитики определения масок с конкретной камеры.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @return {Promise.<CrowdSettings>}
     */
    async [ACTION_LOAD_CROWD_SETTINGS]({dispatch}, {cameraNumber}) {
      const crowdsSettings = await dispatch(ACTION_LOAD_CROWDS_SETTINGS, {camerasNumbers: [cameraNumber]});
      if (_.isEmpty(crowdsSettings)) {
        return null;
      }
      return crowdsSettings[0];
    },
    /**
     * Загрузка перечня событий по аналитике масок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Array.<Number>} eventIds
     * @return {Promise.<Array.<CrowdMessage>>}
     */
    async [ACTION_LOAD_CROWD_EVENTS](context, {cameraNumber, eventIds}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/crowd/events/", {
          camera_number: cameraNumber,
          event_ids: eventIds,
        });
        return response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.crowd));
      } catch {
        return [];
      }
    },
    /**
     * Загрузка перечня событий по аналитики автомобильных номеров с камеры для формирования отчета.
     *
     * @param {Function} dispatch
     * @param {String} cameraNumber
     * @param {Number} eventId
     * @return {Promise.<CrowdMessage>}
     */
    async [ACTION_LOAD_CROWD_EVENT]({dispatch}, {cameraNumber, eventId}) {
      const crowdsSettings = await dispatch(ACTION_LOAD_CROWD_EVENTS, {cameraNumber, eventIds: [eventId]});
      if (_.isEmpty(crowdsSettings)) {
        return null;
      }
      return crowdsSettings[0];
    },
    /**
     * Загрузка перечня событий по определению масок с камеры для формирования отчета.
     *
     * @param {Object} context
     * @param {String} cameraNumber
     * @param {Date} archiveFrom
     * @param {Date} archiveTo
     * @param {String} query
     * @return {Promise.<Array.<CrowdMessage>>}
     */
    async [ACTION_LOAD_CROWD_REPORT](context, {cameraNumber, archiveFrom, archiveTo, query = null, page, page_size}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/analytics/crowd/report/", {
          camera_number: cameraNumber,
          start: archiveFrom.toISOString(),
          end: archiveTo.toISOString(),
          query: query,
          page: page,
          page_size: page_size
        });
        const messages = response.data.results.map(rawEventData => makeMessage(rawEventData, cameraNumber, ANALYTICS.crowd));

        return {
          messages,
          response: response.data
        };
      } catch {
        return {
          messages: [],
          response: null
        };
      }
    },
  },
};
