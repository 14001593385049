export const trMessagesCams = {
  linkedAccounts: "Bağlı hesaplar",
  accountAccess: "Hesaba erişim",
  exit: "Çıkış",
  accountRelationsSettings: "Hesap ilişkilerinin ayarları",
  sortBy: "Sırala:",
  language: "Dil",
  languageSelection: "Dil seçimi",
  view: "Görünüm ",
  byAddress: "Adrese göre",
  byHeading: "Başlığa göre",
  byDateAdded: "Eklenme tarihine göre",
  user: "Kullanıcı",
  tile: "Karo",
  list: "Liste",
  cancel: "İptal",
  save: "Kaydet",
  myCameras: "Kameralarım",
  favorites: "Favoriler",
  search: "Arama",
  showAll: "Hepsini göster",
  found: "Bulundu",
  addresses: "Adresler",
  titles: "Başlıklar",
  noResults: "Arama sonucu bulunamadı",
  createFolder: "Klasör Oluştur",
  noCamerasFound: "Kamera bulunamadı",
  saveSelectedCameras: "Seçilen kameraları kaydet",
  enterFolderName: "Klasör adını girin",
  folderCreate: "Klasör oluşturma menüsü",
  saveChanges: "Değişiklikleri kaydet",
  createSubfolder: "Alt klasör oluştur",
  editFolder: "Klasörü düzenle",
  deleteFolder: "Klasörü sil",
  deleteFolderConfirmation: "Uyarı! Klasörü silmek tüm alt klasörleri ve kameraları da silecektir.",
  folderDeletedSuccess: "{folderName} klasörü başarıyla silindi",
  changesSaved: "Değişiklikler kaydedildi",
  errorAddingCameras: "Kameralar eklenirken hata oluştu",
  errorDeletingFolder: "Klasör silinirken hata oluştu",
  createSubfolderFor: "{folderName} için alt klasör oluştur",
  cameraDisabled: "Kamera devre dışı",
  cameraNotFound: "Kamera bulunamadı",
  embedCamera: "Web sitesine yerleştir",
  cameraSelectedForMonitor: "Kamera alarm monitörü için seçildi",
  cameraSelected: "Kamera seçildi",
  viewOnline: "Çevrimiçi görüntüle",
  viewArchive: "Arşivi görüntüle",
  infoOfCamera: "Kamera bilgisi",
  thermalImager: "Termal görüntüleyici",
  carNumberRecognition: "Araç plakası tanıma",
  faceRecognition: "Yüz tanıma",
  helmetRecognition: "Kask tanıma",
  maskDetector: "Maske dedektörü",
  perimeterDetector: "Çevre güvenliği dedektörü",
  crowdDetector: "Kalabalık dedektörü",
  peopleCounter: "Ziyaretçi sayacı",
  motionAlarm: "Hareket alarmı",
  player: "Oynatıcı",
  embed: "Göm",
  thermalVisionReport: "Termal Görüntü Raporu",
  carNumberReport: "Araç Plakası Raporu",
  faceRecognitionReport: "Yüz Tanıma Raporu",
  helmetReport: "Kask Raporu",
  maskReport: "Maske Raporu",
  perimeterReport: "Çevre İhlali Raporu",
  motionAlarmReport: "Hareket Alarmı Raporu",
  crowdReport: "Kalabalık Raporu",
  peopleCountReport: "Ziyaretçi Sayısı Raporu",
  onMap: "Haritada",
  width: "Genişlik",
  height: "Yükseklik",
  fullscreen: "Tam Ekran",
  autoplay: "Otomatik Oynat",
  https: "HTTPS",
  lowLatency: "Düşük Gecikme Modu",
  standardPlayback: "Standart oynatma. Video hafif bir gecikme ile sunulur.",
  lowLatencyPlayback: "Video gecikme olmadan yayınlanır. Bazı tarayıcılarda desteklenmez.",
  sound: "Ses",
  hoursWithoutReload: "Yeniden yüklemeden saatlerce izleme:",
  copyCode: "Kodu Kopyala",
  streamError: "Hata: Akış başlatılamadı",
  copiedToClipboard: "Panoya kopyalandı",
  events: "Olaylar",
  info: "Bilgi",
  cameraNumber: "Kamera №",
  cameraAddress: "Kamera Adresi",
  timezoneOffset: "Zaman Dilimi Ofseti",
  invalidDownloadRange: "İndirilemedi. Seçilen zaman aralığı hatalı, indirme sınırlarını değiştirin.",
  downloadFailed: "İndirilemedi. Lütfen daha sonra tekrar deneyin.",
  addCamera: "Kamera Ekle",
  alarmCell: "Alarm Hücresi",
  streamUnavailable: "Akış kullanılamıyor. Kamerayı değiştirmeniz gerekiyor.",
  alarm: "Alarm",
  deleteCamera: "Hücreden kamerayı sil",
  changeCamera: "Hücredeki kamerayı değiştir",
  moveToAnotherCell: "Başka bir hücreye taşı",
  selectCameraForCell: "Hücre için bir kamera seçin",
  newMosaic: "Yeni Mozaik",
  enterTitle: "Başlık girin",
  addMosaic: "Mozaik Ekle",
  hideArchiveTools: "Arşiv araçlarını gizle",
  showArchiveTools: "Arşiv araçlarını göster",
  archive: "Arşiv",
  cancelChanges: "Tüm değişiklikleri iptal et ve ayarları gizle",
  showSettings: "Mevcut mozaik ayarlarını göster",
  setLowLatency: "Tüm kameralar için düşük gecikme modunu ayarla",
  selectAlarmCameras: "Bu mozaik için alarm kameralarını seçin",
  oneCamera: "1 kamera",
  fourCamerasGrid: "4 kameralı ızgara",
  grid5Title: "Izgara 5",
  grid6Title: "Izgara 6",
  grid8Title: "Izgara 8",
  grid9Title: "Izgara 9",
  grid10Title: "Izgara 10",
  grid16Title: "Izgara 16",
  deleteMosaic: "Mevcut mozaiği sil",
  delete: "Sil",
  alarmCameras: "Alarm Kameraları",
  public: "Kamu kameraları",
  confirmDeleteMosaic: "Bu mozaiği silmek istiyor musunuz?",
  deleteMosaicError: "Mozaik silinirken hata oluştu",
  deleteMosaicSuccess: "Mozaik silindi",
  saveChangesError: "Kaydetme sırasında bir hata oluştu. Lütfen sayfayı yenileyip tekrar deneyin.",
  saveMosaicError: "Mozaik kaydedilirken bir hata oluştu. Lütfen yeni bir mozaik oluşturmayı deneyin.",
  saveChangesSuccess: "Mozaik kaydedildi",
  newMosaicAdded: "Yeni mozaik eklendi",
  defaultMosaicRedirect: "Mozaik başarıyla kaydedildi",
  chooseCamerasForAlarm: "Mozaikteki alarm olayları için kameraları seçin",
  setAlarmCameras: "'Kaydet' tuşuna basarak seçilen alarm kameralarını etkinleştirin",
  setFullscreen: "Tam ekran ayarla",
  archiveSettings: "Arşiv Ayarları",
  hideEventsCount: "Gizli olaylar: ",
  hiddenThermalVisionEvents: "Termal görüntü için gizli olaylar: ",
  hiddenCarNumberEvents: "Araç plakası için gizli olaylar: ",
  hiddenFaceRecognitionEvents: "Yüz tanıma için gizli olaylar: ",
  hiddenHelmetEvents: "Kask için gizli olaylar: ",
  hiddenMotionAlarmEvents: "Hareket alarmı için gizli olaylar: ",
  hiddenMaskEvents: "Maske için gizli olaylar: ",
  hiddenCrowdEvents: "Kalabalık için gizli olaylar: ",
  hiddenPerimeterEvents: "Çevre güvenliği için gizli olaylar: ",
  hiddenPeopleCounterEvents: "Kişi sayacı için gizli olaylar: ",
  editTitleTooltip: "Düzenle",
  titleSaveSuccess: "Kamera adı başarıyla değiştirildi",
  titleSaveError: "Hata: ",
  titleWarning: "Başlık uzunluğu 64 karakteri geçemez ve boş olamaz",
  noEvents: "Bu zaman aralığında olay yok.",
  deleteConfirmation: "Hesapla bağlantıyı sil?",
  accessHeader: "Hesap Erişimi",
  nothingHere: "Burada bir şey yok",
  createTokenMessage: "API aracılığıyla hizmetimize üçüncü taraf sistemlerin entegrasyonu için yeni bir belirteç oluşturun",
  createToken: "Belirteç Oluştur",
  username: "Kullanıcı Adı",
  link: "Bağlantı",
  token: "Belirteç",
  copy: "Kopyala",
  deleteToken: "Belirteci Sil",
  copySuccess: "Panoya kopyalandı",
  addLinkPrompt: "Başka bir hesaptan kameraları bağlamak için ekleye tıklayın",
  add: "Ekle",
  enterUsername: "Kullanıcı adını girin",
  password: "Şifreyi girin",
  accountLinkedSuccess: "Hesap başarıyla bağlandı",
  inputUsernamePassword: "Kullanıcı adı ve şifre girin:",
  employees: "Çalışanlar",
  accessDevices: "Erişim Cihazları",
  accessGroups: "Erişim Grupları",
  cars: "Arabalar",
  searchPlaceholder: "Arama",
  filterByGroupsPlaceholder: "Erişim Gruplarına Göre Filtrele",
  addToGroup: "Gruba Ekle",
  removeFromGroup: "Gruptan Çıkar",
  deleteEmployees: "Çalışanları Sil",
  addEmployee: "Ekle",
  addEmployeeFromFile: "Dosyadan Ekle",
  downloadReport: "Raporu İndir",
  deleteEmployeeConfirm: "Bu çalışanı silmek istiyor musunuz?",
  deleteEmployeesConfirm: "Seçilen çalışanları silmek istiyor musunuz?",
  employeesDeleted: "Çalışanlar silindi",
  employeeDeleted: "Çalışan silindi",
  errorDeletingEmployee: "Çalışanı silerken hata oluştu",
  viewAll: "Hepsini Görüntüle",
  deleteSelectedEmployeesConfirm: "Seçilen çalışanları silmek istiyor musunuz?",
  removeEmployeesFromGroup: "Çalışanları gruptan çıkar",
  addEmployeesToGroup: "Çalışanları gruba ekle",
  csvFileName: "Çalışanlar raporu.csv",
  totalEmployees: "Toplam çalışan sayısı:",
  tableHeaders: {
    id: "ID",
    name: "İsim",
    cars: "Araçlar",
    deviceAccessGroups: "Erişim Grupları",
    isActive: "Aktif",
    identifier: "Kimlik",
    emergencyVehicleAccess: "Acil Araç Girişi",
    cameraCount: "Kamera Sayısı",
    cameras: "Kameralar",
    title: "Başlık",
    about: "Ek Açıklama",
    employee_count: "Çalışan Sayısı",
    employee_ids: "Grup Çalışanları",
  },
  entry: "kayıtlar",
  employeeName: "Çalışan Adı",
  enterEmployeeName: "Çalışanın adını girin",
  selectAccessGroups: "Erişim gruplarını seçin ve çalışan cihazlara erişim sağlayacaktır",
  carNumbers: "Araç Numaraları",
  accountNumber: "Hesap (sözleşme numarası)",
  isActive: "Aktif mi?",
  saveAndAddNew: "Kaydet ve yeni çalışan ekle",
  successEmployeeCreated: "Çalışan başarıyla oluşturuldu",
  uploadFile: "Dosya Yükle",
  getTemplate: "Şablonu Al",
  successFileUploaded: "Dosya başarıyla yüklendi",
  errorFileUpload: "Hata: ",
  errorLoadEntity: "Hata: Bilgi görüntülenemiyor.",
  deviceAccessGroups: "Cihaz Erişim Grupları",
  addPhoto: "Fotoğraf Ekle",
  deletePhoto: "Fotoğrafı Sil",
  noPhotos: "Fotoğraf Yok",
  attachedUsernames: "Bariyer Erişimi için Hesap (Sözleşme Numarası)",
  employeeCarsCount: "Çalışanın araç sayısı: ",
  accessSettings: "Erişim Ayarları",
  accessGroupList: "Erişim Grupları Listesi:",
  viewGroup: "Grubu Görüntüle: ",
  confirmDeletePhoto: "Bu fotoğrafı silmek istiyor musunuz?",
  errorDeletePhoto: "Fotoğraf silinirken hata oluştu",
  successSave: "Başarıyla kaydedildi",
  confirmDeleteEmployee: "Bu çalışanı silmek istiyor musunuz?",
  errorDeleteEmployee: "Çalışan silinirken hata oluştu",
  addDevice: "Cihaz Ekle",
  chooseDevice: "Cihaz Seç",
  enterEmployee: "Çalışan Gir",
  more: "Daha Fazla",
  deviceFilter: "Cihazlara Göre Filtrele",
  employeeFilter: "Çalışanlara Göre Filtrele",
  device: "Cihaz",
  employee: "Çalışan",
  accessLevel: "Erişim Seviyesi",
  notFound: "Yetki bulunamadı.",
  foundCount: "Bulunan: ",
  addPermissionError: "Yetkileri eklerken hata oluştu",
  setPermissionError: "Yetkileri ayarlarken hata oluştu",
  deletePermissionConfirm: "Bu yetkileri silmek istiyor musunuz?",
  devicePermissionDeleted: "Cihaz yetkileri silindi",
  deletePermissionError: "Yetkileri silerken hata oluştu",
  fullAccess: "Tam Erişim",
  entrance: "Giriş",
  selectAccessGroupForDelete: "Çalışanları çıkarmak için erişim grubunu seçin ",
  selectAccessGroupForAdd: "Çalışanları eklemek için erişim grubunu seçin ",
  enterAccessGroup: "Erişim grubunu girin",
  deleteFromGroup: "Bu gruptan çıkar",
  errorDeleteEmployeesFromGroup: "Çalışanları gruptan çıkarırken hata oluştu. ",
  errorAddEmployeesToGroup: "Çalışanları gruba eklerken hata oluştu. ",
  noData: "Veri yok",
  totalCount: "Toplam",
  selectDevicePlaceholder: "Cihaz seçin",
  selectGroupPlaceholder: "Erişim grubu adını girin",
  addGroup: "Çalışan grubu ekle",
  groupFilter: "Erişim gruplarına göre filtrele",
  deviceHeader: "Cihaz",
  groupHeader: "Erişim Grubu",
  noPermissions: "İzin bulunamadı",
  errorAddingPermissions: "Yetkileri eklerken hata oluştu",
  errorUpdatingPermissions: "Yetkileri güncellerken hata oluştu",
  confirmDeletePermission: "Bu yetkileri silmek istiyor musunuz?",
  permissionDeleted: "Yetki silindi",
  errorDeletingPermissions: "Yetkileri silerken hata oluştu",
  allRowsSelected: "Tüm satırlar seçildi",
  selectedRows: "Seçilen satırlar",
  selectAll: "Hepsini seç",
  deviceName: "Cihaz Adı",
  deviceState: "Cihaz Durumu",
  active: "Aktif",
  inactive: "Pasif",
  emergencyVehicleAccess: "Acil Araç Girişi",
  available: "Var",
  notAvailable: "Yok",
  cameraCountForDevice: "Cihaza bağlı kamera sayısı",
  availableCamerasForUser: "Mevcut kullanıcı için kullanılabilir kamera sayısı",
  noAvailableCamerasForUser: "Mevcut kullanıcı için kullanılabilir kamera yok.",
  noCamerasAttachedToDevice: "Cihaza bağlı kamera yok.",
  editPermissionsForEmployees: "Çalışanların İzinlerini Düzenle",
  editPermissionsForDeviceAccessGroups: "Erişim Gruplarının İzinlerini Düzenle",
  editAccessSettings: "Erişim Ayarları",
  deleteGroups: "Grupları Sil",
  createAccessGroup: "Erişim Grubu Oluştur",
  confirmDeleteGroup: "Bu erişim grubunu silmek istiyor musunuz?",
  groupDeleted: "Erişim grubu silindi",
  errorDeletingGroup: "Erişim grubunu silerken hata oluştu",
  confirmDeleteGroups: "Seçilen erişim gruplarını silmek istiyor musunuz? Seçilen: {count}",
  groupsDeleted: "Erişim grupları silindi",
  errorDeletingGroups: "Erişim grupları silinirken hata oluştu",
  groupTitle: "Grup Başlığı",
  groupAbout: "Ek Açıklama",
  employeesCount: "Grup'taki çalışan sayısı: ",
  viewAllEmployees: "Tüm çalışanları görüntüle",
  addEmployeeToGroup: "Gruba çalışan ekle",
  saveSuccess: "Başarıyla kaydedildi",
  deleteGroupConfirm: "Bu erişim grubunu silmek istiyor musunuz?",
  employeeAddedToGroup: "Çalışan gruba eklendi",
  errorAddingEmployees: "Çalışanlar gruba eklenirken hata oluştu.",
  groupName: "Grup Adı",
  additionalDescription: "Ek Açıklama",
  filterByEmployee: "Çalışanlara göre filtrele",
  deleteCars: "Arabaları sil",
  addCar: "Araba ekle",
  createCarDialogTitle: "Araç oluştur",
  confirmDeleteCar: "Bu aracı silmek istiyor musunuz?",
  carDeleted: "Araç silindi",
  errorDeleteCar: "Araç silinirken hata oluştu",
  confirmDeleteSelectedCars: "Seçili araçları silmek istiyor musunuz? Seçilen: {count}",
  carsDeleted: "Araçlar silindi",
  errorDeleteCars: "Araçlar silinirken hata oluştu",
  carNumber: "Araba numarası",
  carOwner: "Araç sahibi",
  existingEmployeeCars: "Mevcut çalışan arabaları",
  shownCars: "Çalışanın gösterilen arabaları",
  totalCars: "Toplam arabaları",
  accountLinkedTo: "Hesap bağlı",
  deleteAccountLinkConfirmation: "Hesapla bağlantıyı sil?",
  accessGranted: "Erişim verildi",
  name: "Ad",
  number: "Numara",
  access: "Erişim",
  date: "Tarih",
  yes: "Evet",
  no: "Hayır",
  unknown: "Bilinmeyen",
  startDate: "Başlangıç Tarihi",
  endDate: "Bitiş Tarihi",
  reset: "Sıfırla",
  itemsPerPage: "Sayfa başına öğeler",
  screenshot: "Ekran Görüntüsü",
  video: "Video",
  downloadVideo: "Videoyu indir",
  text: "Metin",
  archiveError: "Hata: arşive erişilemiyor",
  crowdDetection: "Kalabalık Algılandı",
  screenshotEvent: "Etkinlik Ekran Görüntüsü",
  eventVideo: "Etkinlik Videosu",
  downloadEventVideo: "Etkinlik Videosunu İndir",
  report: "Rapor",
  noHelmet: "Kask Yok",
  noMask: "Maske Yok",
  duration: "Süre (saniye cinsinden)",
  reportType: "Rapor Türü",
  inCount: "Giren kişi sayısı",
  outCount: "Çıkan kişi sayısı",
  defaultReport: "Genel rapor",
  hourly: "Saatlik",
  daily: "Günlük",
  weekly: "Haftalık",
  monthly: "Aylık",
  perimeterCrossing: "Çevre Aşımı",
  minTemperature: "En Düşük Sıcaklık",
  maxTemperature: "En Yüksek Sıcaklık",
  temperature: "Sıcaklık (°C)",
  exceededTemperature: "Sıcaklık Aşıldı mı?",
  maxRowsWarning:
    "Maksimum 1000 satır görüntüleniyor, ek kayıtlar gizlenmiş olabilir. Görüntülemek için filtreleri daraltın.",
  thermalCameras: "Termal Kameralar",
  motions: "Hareketler",
  helmets: "Kasklar",
  masks: "Maskeler",
  crowdGathering: "Kalabalık Toplanması",
  zoneCrossing: "Bölge Geçişi",
  tooManyRows: "Görüntülenen maksimum satır sayısı 1000'dir, diğer kayıtlar gizlenmiş olabilir. Onları görmek için filtreleri daraltın.",
  eventText: "Olay",
  eventDate: "Tarih",
  crowdReportFilename: "Toplanma Raporu",
  defaultCrowdEvent: "Kalabalık Toplanması",
  helmetReportFilename: "Kask Raporu",
  helmetMissing: "Kask Eksik",
  maskReportFilename: "Maske Raporu",
  perimeterSecurityReportFilename: "Çevre Güvenliği Raporu",
  unknownEvent: "Bilinmiyor",
  loadingError: "Veriler yüklenirken bir hata oluştu. Lütfen filtreleri daraltın.",
  motionReportFilename: "Hareket Algılama Raporu",
  thermalReportFilename: "Termal Rapor",
  title: "İsim",
  tariff: "Tarife",
  address: "Adres",
  firmwareUpdateMessage: "Firmware güncelleniyor… sayfayı kapatmayın veya yenilemeyin.",
  cameraModel: "Kamera modeli",
  macAddress: "MAC adresi",
  firmwareVersion: "Firmware versiyonu",
  firmwareUpdateAvailable: "Güncelleme mevcut",
  enableVideoStream: "Video akışını etkinleştir",
  enableAudio: "Ses",
  ntpServer: "NTP sunucusu",
  videoResolution: "Çözünürlük",
  videoBitrate: "Bit hızı",
  nightModeEnable: "Gece modu",
  timeZone: "Saat dilimi",
  rebootCamera: "Kamerayı yeniden başlat",
  confirmReboot: "Kamerayı yeniden başlatmak istiyor musunuz?",
  cameraRebootIn15Sec: "Kamera 15 saniye içinde yeniden başlatılacak",
  configurationError: "Yapılandırma sırasında hata oluştu",
  confirmUpgradeFirmware: "Firmware sürümü {version} yüklenecek. Kamerayı güç veya internetten ayırmayın. Bu, kameraya zarar verebilir.",
  upgradeSuccess: "Firmware 45 saniye içinde güncellenecek",
  upgradeError: "Bir hata oluştu, lütfen tekrar deneyin",
  screenshotAlt: "Etkinlik ekran görüntüsü",
  screenshotProcessing: "Ekran görüntüsü işleniyor.",
  Movement: "Hareket",
  continues: "devam ediyor",
  sec: "sn.",
  employeeId: "çalışan",
  deviceId: "cihaz",
  specialTransport: "Özel Taşıma",
  eventView: 'Etkinlik Görüntüsü',
  downloadError: 'İndirilemedi. Lütfen daha sonra tekrar deneyin.',
  screenshotView: 'Ekran Görüntüsü İzlemesi',
  participants: '&copy; Katkıda Bulunanlar {link}',
  insufficientRights: "Yetersiz haklar.",
  liveStreamTokenError: "Canlı yayına erişim için bir token alınamadı.",
  archiveTokenError: "Arşiv erişimi için bir token alınamadı.",
  multiscreen: "Çoklu ekran",
  map: "harita",
  scud: "ACS",
  setupCameras: "Kameraları ayarlama",
  reloadToUpdate: "Sayfayı yenileyip güncellemek istiyor musunuz?",
  reloadNow: "Şimdi Yenile",
  postpone: "Ertele",
  newVersion: "Uygulamanın yeni bir sürümü mevcut",
  errorWhileLoading: "Sayfa yüklenirken bir sorun oluştu. Sayfayı yeniden yüklemeyi deneyelim.",
  reload: "Yeniden yükle",
  errorOccurred: "Bir hata oluştu",
  on: "Açık",
  off: "Kapalı",
  auto: "Otomatik mod",
  maxRowLimit: "Dışa aktarma için maksimum satır sayısı aşıldı ",
};
