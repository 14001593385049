<template>
  <div class="analytic-report">
    <SpinnerLoading v-if="isLoading" class="loader_center" color="blue" />

    <template v-else-if="cameraInfo && cameraInfo.isAvailableArchive()">
      <div class="analytic-report__settings">
        <SmartInputDate
          v-model="archiveFrom"
          :config-flat-pickr="{minDate: minEventArchiveFrom, maxDate: new Date()}"
          :caption="$t('startDate')"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartInputDate
          v-model="archiveTo"
          :config-flat-pickr="{minDate: archiveFrom, maxDate: new Date()}"
          :caption="$t('endDate')"
          class="analytic-report__settings-control analytic-report__settings-control_small"
        />
        <SmartInputText
          v-model="searchText"
          :caption="$t('search')"
          class="analytic-report__settings-control"
        />
        <CamsButton class="analytic-report__settings-control" type="button" @click="resetFilters()">
          {{ $t('reset') }}
        </CamsButton>
        <CamsButton class="analytic-report__settings-control" priority="primary" type="button" @click="downloadTableAsCSVReport()">
          {{ $t('downloadReport') }}
        </CamsButton>
      </div>
      <div class="analytic-report__pagination">
        <paginate
          v-model="currentPage"
          class="analytic-report__pagination_smart-pagination"
          :click-handler="loadReport"
          :page-count="allPages"
          :page-range="5"
          active-class="smart-pagination__page_active"
          break-view-class="smart-pagination__page_collapse"
          container-class="smart-pagination"
          next-class="smart-pagination__page smart-pagination__next-prev"
          next-link-class="smart-pagination__page-link small"
          next-text="<svg class='icon icon-arrow-left'><use xlink:href='#icon-arrow-left'></use></svg>"
          page-class="smart-pagination__page"
          page-link-class="smart-pagination__page-link small"
          prev-class="smart-pagination__page smart-pagination__next-prev"
          prev-link-class="smart-pagination__page-link small"
          prev-text="<svg class='icon icon-arrow-right'><use xlink:href='#icon-arrow-right'></use></svg>"
        />
        <SmartSelect
          v-model="pageSize"
          :options="optionsPageSize"
          :caption="$t('itemsPerPage')"
          width="fill"
          class="analytic-report__pagination_smart-select-pagination"
          size="s"
          @input="setPageSize(pageSize)"
        />
      </div>
      <div class="analytic-report__table cams-table-wrapper">
        <SpinnerLoading v-if="isLoadingReport" class="loader_center" color="blue" />

        <table v-else-if="messages" class="cams-table">
          <tr>
            <th class="cams-table__cell cams-table__cell_fixed-width" />
            <th class="cams-table__cell">
              {{ $t('text') }}
            </th>
            <th class="cams-table__cell">
              {{ $t('date') }}
            </th>
          </tr>
          <template v-for="message in messages">
            <tr :key="message.id" :class="{'warning warning-bg': message.alarm}">
              <td class="cams-table__cell cams-table__cell_fixed-width">
                <div class="analytic-report__event-actions">
                  <CamsButton
                    icon-type="only"
                    :title="$t('screenshotEvent')"
                    type="button"
                    @click="showFullScreenshot(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-screen-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('eventVideo')"
                    type="button"
                    @click="playEventStart(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="#icon-video-preview" />
                    </svg>
                  </CamsButton>
                  <CamsButton
                    v-show="message.date >= minVideoArchiveFrom"
                    icon-type="only"
                    :title="$t('downloadEventVideo')"
                    type="button"
                    @click="downloadEventVideo(message, cameraInfo)"
                  >
                    <svg>
                      <use xlink:href="../../../assets/img/icons.svg#cloud-archive" />
                    </svg>
                  </CamsButton>
                </div>
              </td>
              <td class="cams-table__cell">
                {{ message.text || $t('crowdDetection') }}
              </td>
              <td class="cams-table__cell">
                {{ message.date | localDateTime }}
              </td>
            </tr>
          </template>
        </table>

        <template v-else>
          <p>{{ $t('noEvents') }}</p>
        </template>
      </div>
    </template>

    <template v-else>
      <p>{{ $t('archiveError') }}</p>
    </template>

    <!--iframe для скачивания-->
    <iframe :src="downloadUrl" frameborder="0" height="1" width="1" />
  </div>
</template>

<script>
import {analyticReportMixin} from "@/components/oneScreen/reports/mixins.js";
import {ACTION_LOAD_CROWD_REPORT} from "@/store/analytics/crowds/index.js";

/**
 * Отчет по скоплению людей.
 */
export default {
  name: "CrowdReportScreen",
  mixins: [
    analyticReportMixin,
  ],
  data() {
    return {
      archiveFrom: null,
      archiveTo: null,
      searchText: null,
    };
  },
  computed: {
    /**
     * @return {Date} Корректное представление archiveFrom в сравнении с archiveTo.
     */
    clearArchiveFrom() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.min(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
    /**
     * @return {Date} Корректное представление archiveTo в сравнении с archiveFrom.
     */
    clearArchiveTo() {
      return this.archiveFrom && this.archiveTo ? new Date(Math.max(this.archiveFrom.getTime(), this.archiveTo.getTime())) : null;
    },
  },
  watch: {
    // Отслеживание значений фильтров для автоматического обновления отчета.
    archiveFrom(newStart) {
      if (newStart && this.archiveTo && this.archiveTo < newStart) {
        this.archiveTo = newStart;
      }
      this.debouncedLoadReport();
    },
    archiveTo(newEnd) {
      if (newEnd && this.archiveFrom && newEnd < this.archiveFrom) {
        this.archiveTo = this.archiveFrom;
      }
      this.debouncedLoadReport();
    },
    searchText() {
      this.debouncedLoadReport();
    },
    currentPage() {
      this.debouncedLoadReport();
    },
    pageSize() {
      this.debouncedLoadReport();
    },
  },
  /**
   * Регистрируется отложенная загрузка отчета для случаев быстрого изменения фильтров.
   */
  created() {
    this.debouncedLoadReport = _.debounce(this.loadReport, 350);
  },
  methods: {
    /**
     * Сброс фильтров в начальное состояние.
     * Фильтры по умолчанию по датам за последний час.
     */
    resetFilters() {
      const archiveTo = new Date(),
            archiveFrom = new Date(archiveTo.getTime());
      archiveFrom.setHours(archiveTo.getHours() - 1);
      this.archiveFrom = archiveFrom;
      this.archiveTo = archiveTo;
      this.searchText = "";
      this.currentPage = 1;
    },
    /**
     * Загрузка отчета по заданным параметрам.
     */
    async loadReport() {
      if (!this.clearArchiveFrom || !this.clearArchiveTo) {
        return;
      }
      this.isLoadingReport = true;
      const {messages, response} = await this.$store.dispatch(`analytics/crowds/${ACTION_LOAD_CROWD_REPORT}`,
                                                              {
                                                                cameraNumber: this.cameraInfo.number,
                                                                archiveFrom: this.clearArchiveFrom,
                                                                archiveTo: this.clearArchiveTo,
                                                                query: this.searchText,
                                                                page: this.currentPage,
                                                                page_size: this.pageSize,
                                                              }
      );
      this.messages = messages || []; // Если данных нет, устанавливаем пустой массив
      this.allPages = response.page.all || 1; // Общее количество страниц
      this.currentPage = response.page.current || 1; // Текущая страница
      this.currentPage > this.allPages ? this.currentPage = 1 : this.currentPage;
      this.isLoadingReport = false;
    },
    /**
     * Выгрузка данных таблицы в виде CSV.
     */
    async downloadTableAsCSVReport () {
      this.isLoadingReport = true;
      let dateFrom = moment.tz(this.clearArchiveFrom, moment.tz.guess()).format("DD.MM.YYYY_HH.mm");
      let dateTo = moment.tz(this.clearArchiveTo, moment.tz.guess()).format("DD.MM.YYYY_HH.mm");
      const apiAction = `analytics/crowds/${ACTION_LOAD_CROWD_REPORT}`;
      const params = {
        cameraNumber: this.cameraInfo.number,
        archiveFrom: this.clearArchiveFrom,
        archiveTo: this.clearArchiveTo,
        query: this.searchText,
      };
      const headers = [
        this.$t('text'),
        this.$t('date')
      ];
      const filenameBuilder = `${this.$t('crowdDetection')}_${this.cameraInfo.number}_${dateFrom}-${dateTo}.csv`;
      const rowBuilder = message => `${this.$t('crowdDetection')},${this.$options.filters.localDateTime(message.date)}\r\n`;

      await this.downloadTableAsCSV(apiAction, params, headers, filenameBuilder, rowBuilder);
      this.isLoadingReport = false;
    },
  },
};
</script>

<style lang="scss">
@import "./analytic-report.scss";
</style>
